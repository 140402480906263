import React, { useRef, useState } from "react";

export const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handlePipToggle = async () => {
    if (!document.pictureInPictureElement) {
      try {
        await videoRef.current.requestPictureInPicture();
      } catch (error) {
        console.error("Error entering Picture-in-Picture mode:", error);
      }
    } else {
      try {
        await document.exitPictureInPicture();
      } catch (error) {
        console.error("Error exiting Picture-in-Picture mode:", error);
      }
    }
  };

  const handleMetadataLoaded = () => {
    setIsVideoReady(true);
  };

  return (
    <div className="video-player-container">
      <video
        ref={videoRef}
        controls
        width="600"
        onLoadedMetadata={handleMetadataLoaded}
        src="https://www.youtube.com/watch?v=eTPMvQzOwEs&list=RDeTPMvQzOwEs&start_radio=1"
      >
        Your browser does not support the video tag.
      </video>
      <button
        onClick={handlePipToggle}
        className="btn"
        disabled={!isVideoReady}
      >
        Toggle Picture-in-Picture
      </button>
    </div>
  );
};
