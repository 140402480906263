import React, { useState } from "react";

export default function AgentPhoneDetailsTable({ data }) {
  return (
    <div className="relative overflow-x-auto bg-gray-900 border-gray-800 rounded-md">
      <div className=" flex justify-between ">
        <div className="flex flex-col justify-between px-6 py-2">
          <div className="text-sm text-[#AEB9E1] font-medium">
            Agents Details
          </div>
        </div>
      </div>
      {/*  */}
      <table className="w-full bg-gray-900 p-6 text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-[#87888C] uppercase">
          <tr>
            <th scope="col" className="px-6 py-3 w-3/12">
              Agent Name
            </th>
            <th scope="col" className="px-6 py-3 w-3/12">
              Phone Number
            </th>
          </tr>
        </thead>

        <tbody>
          {data?.map((agent, index) => (
            <tr key={index} className="border-t border-gray-700 h-[30px]">
              <td
                scope="row"
                className={`px-6 py-2 font-medium text-white whitespace-nowrap w-3/12`}
              >
                {agent?.name}
              </td>

              <td className="px-6 py-2 text-white w-3/12">
                {agent?.deployed_phone_number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
