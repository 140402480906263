import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import AgentTaskTable from "../table/AgentTaskTable";
import TransactionHistory from "./TransactionHistory";
import { useSelector } from "react-redux";
import {
  getAgents,
  getAgentTasks,
  getAgentWeeklyTimeSaveReport,
  selectAgent,
} from "../../redux/agentSlice";
import { getAllWorkflow, getCategories } from "../../redux";
import { capitalizeFirstLetter } from "../../utils";
import { useNavigate } from "react-router-dom";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const AgentDashboard = () => {
  const agent = useSelector(selectAgent);
  const agents = useSelector(getAgents);
  const workflows = useSelector(getAllWorkflow);
  const categories = useSelector(getCategories);
  const agentTasks = useSelector(getAgentTasks);
  const agentTimeSaved = useSelector(getAgentWeeklyTimeSaveReport);
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  useEffect(() => {
    if (Object.keys(agentTimeSaved).length > 0) {
      setKeys(Object.keys(agentTimeSaved?.daily_time_saved_in_hours));
      setValues(Object.values(agentTimeSaved?.daily_time_saved_in_hours));
    }
  }, [agentTimeSaved]);
  const data = {
    labels: keys,
    datasets: [
      {
        label: "Time Saved",
        data: values,
        backgroundColor: "#A9DFD8",
        borderRadius: 5,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div className="w-full bg-black min-h-screen p-8 text-white flex flex-col items-center customScroll">
      {agents?.length > 0 ? (
        <div className="w-full">
          {/* Top */}
          <div className="flex justify-between items-center mb-4">
            {/* child 1 */}
            <div className="flex items-center gap-8">
              <div className="font-medium text-lg text-text-3">
                {agent?.name}
              </div>
              <div
                style={{ background: "rgba(255, 255, 255, 0.11)" }}
                className="flex gap-2 font-medium text-md text-text-3 px-[15px] py-[7px]"
              >
                <p>Status:</p>{" "}
                <p
                  className={`${
                    agent?.agent_status === "active"
                      ? "text-[#58CE5E]"
                      : agent?.agent_status === "inactive"
                      ? "text-red-500"
                      : "text-red-500"
                  }`}
                >
                  {agent?.agent_status !== undefined
                    ? capitalizeFirstLetter(agent?.agent_status)
                    : agent?.agent_status}
                </p>
              </div>
            </div>
            {/* child 2 */}
            <div className="flex gap-2">
              <div
                style={{ background: "rgba(255, 255, 255, 0.11)" }}
                className="flex gap-2 font-medium text-md text-text-3 px-[15px] py-[7px]"
              >
                <p>Workflow: </p>{" "}
                <p className="">
                  {
                    categories?.find(
                      (category) =>
                        category?.id ===
                        workflows?.find((e) => e.id === agent?.workflow_id)
                          ?.workflow_category_id
                    )?.category_name
                  }{" "}
                </p>
              </div>
              <div
                style={{ background: "rgba(255, 255, 255, 0.11)" }}
                className="flex gap-2 font-medium text-md text-text-3 px-[15px] py-[7px]"
              >
                <p>Deployment: </p> <p className="">Phone</p>
              </div>
            </div>
          </div>
          {/* Center */}
          <div className="flex gap-4">
            <div className="w-[60%] min-h-[290px]">
              {/* {agentTasks?.length > 0 && <AgentTaskTable data={agentTasks} />} */}
              <AgentTaskTable data={agentTasks} />
              {/* <AgentPODTable data={podTaskData} /> */}
            </div>

            <div className="w-[40%] bg-dark-3 p-4 rounded-lg shadow-md">
              <div className="text-lg font-semibold mb-4">
                Time Saved:{agentTimeSaved?.total_time_saved_in_hours}
              </div>
              {/* If daily time saved is greater than zero */}
              {data?.datasets?.length > 0 ? (
                <Bar data={data} options={options} />
              ) : (
                <div className="h-32 text-center text-white py-4">
                  No Time Saved
                </div>
              )}
              {/* Break */}
              <div className="w-full h-[1px] bg-[#E5E5E5] my-4 opacity-5"></div>

              <div className="w-full flex justify-center items-center gap-4">
                {/* Hours */}
                <div className="w-[20%] flex justify-center items-center gap-2">
                  <div className="bg-[#A9DFD8] w-2 h-2 rounded-lg"></div>{" "}
                  <p className="text-[#A0A0A0] font-medium text-xs">Hours</p>
                </div>
              </div>
            </div>
          </div>
          {/* bottom */}
          <div className="w-full mt-4">
            <TransactionHistory transactions={agentTasks} />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-center items-center h-screen">
          <h1 className="text-3xl text-white">
            Please Add New Agent To Continue
          </h1>
        </div>
      )}
    </div>
  );
};

export default AgentDashboard;
