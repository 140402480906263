import React, { useState } from "react";
import { calculateTimeElapsed } from "../../utils";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAgentTasks, selectAgent, setAgentTasks } from "../../redux";
import { getAgentTasksById } from "../../api/agent";

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 2;
  // Calculate the range of tasks to display
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const agentTasks = useSelector(getAgentTasks);
  const agent = useSelector(selectAgent);
  const dispatch = useDispatch();
  const filteredTasks = agentTasks.slice(indexOfFirstTask, indexOfLastTask);
  // Calculate the total number of pages
  const handleFetchTasks = (agentId, limit, skip) => {
    getAgentTasksById(agentId, limit, skip)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAgentTasks(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const limit = 1;
  let skip = 0;

  const totalPages = Math.ceil(agentTasks.length / tasksPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handleFetchTasks(agent?.id, limit, skip);
      setCurrentPage(currentPage + 1);
      skip += limit;
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="bg-dark-3 text-white p-6 rounded-xl shadow-lg w-full max-h-[400px] simpleScroll">
      <div className="flex justify items-center mb-4 gap-4">
        <h2 className="text-lg font-semibold text-white">Activity History</h2>
        <div
          style={{ background: "rgba(87, 93, 255, 0.5)" }}
          className="text-[#D1DBF9] px-[6px] py-[2px] rounded-sm text-xs font-medium"
        >
          {agentTasks.length}
        </div>
      </div>
      <div className="space-y-6">
        {filteredTasks?.length > 0 ? (
          filteredTasks.map((transaction, index) => (
            <div
              key={index}
              className="flex justify-between items-start space-x-4"
            >
              <div className="w-full">
                <div className="w-[80%] flex justify-between">
                  <div className="font-normal text-xs text-white">
                    {transaction?.task_name}:
                  </div>
                  <div className="text-[#AEB9E1] font-medium text-xs">
                    {calculateTimeElapsed(transaction?.task_performed_at)}
                  </div>
                </div>
                <p className="text-[#7E89AC] font-normal text-xs mt-2">
                  {transaction?.task_summary}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="h-32 text-center text-white py-4">
            No Transactions Performed
          </div>
        )}
      </div>
      {filteredTasks.length > 0 ? (
        <div>
          <div className="flex justify-end items-center text-sm text-gray-400 mt-2">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`px-2 py-1 rounded ${
                currentPage === 1
                  ? "text-white cursor-not-allowed"
                  : "text-white"
              }`}
            >
              <MdKeyboardArrowLeft size={16} />
            </button>
            {/* Count */}
            <div className="text-white text-xs opacity-85">{`${
              indexOfFirstTask + 1
            } - ${indexOfLastTask} of ${agentTasks.length}`}</div>
            {/* count end */}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`px-2 py-1 rounded ${
                currentPage === totalPages
                  ? "text-white cursor-not-allowed"
                  : "text-white"
              }`}
            >
              <MdKeyboardArrowRight size={16} />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TransactionHistory;
