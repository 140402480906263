import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import workflowReducer from "./workflowsSlice";
import agentReducer from "./agentSlice";
import filterReducer from "./filterSlice";

export const store = configureStore({
  reducer: {
    agent: agentReducer,
    workflow: workflowReducer,
    user: userReducer,
    filter: filterReducer,
  },
});
