import React, { useState } from "react";
import FilterMenu from "../dashboard/FilterMenu";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DetailModal from "../agentDetail/DetailModal";
import { getCallTaskById } from "../../api/callTask";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { setSingleTaskData } from "../../redux";
import { useDispatch } from "react-redux";

export default function AgentTaskTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const options = ["Completed", "Pending"];
  const dispatch = useDispatch();
  const tasksPerPage = 5;
  const navigate = useNavigate();
  // Calculate the range of tasks to display
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = data.slice(indexOfFirstTask, indexOfLastTask);

  // Filter Tasks
  const filteredTasks =
    selectedFilter === "All"
      ? currentTasks
      : currentTasks?.filter((task) => task?.task_status === selectedFilter);

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / tasksPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // const handleCallDetails = (id) => {
  //   setIsLoading(true);
  //   getCallTaskById(id)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setSelectedTask(res?.data);
  //       }
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => {
  //       // Disable loading after all operations are done
  //       setIsLoading(false);
  //     });
  // };
  const handleTaskDetails = (detail, id) => {
    dispatch(setSingleTaskData(detail));
    navigate(`/agent-task-details/${detail?.id}`);
    setSelectedTask(detail);
  };
  return (
    <div className="relative overflow-x-auto bg-dark-3 border-gray-800 rounded-md min-h-[290px]">
      <div className=" flex justify-between ">
        <div className="flex flex-col justify-between px-6 py-2">
          <div className="text-lg text-white font-medium">Agent Tasks</div>
        </div>
        <FilterMenu options={options} selectFilter={setSelectedFilter} />
      </div>
      {/* Condition */}
      {filteredTasks.length > 0 ? (
        <table className="w-full min-h-[250px] bg-dark-3 p-6 text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-[#87888C]">
            <tr>
              <th scope="col" className="text-[13px] px-6 pb-3 w-3/12">
                Task ID
              </th>
              <th scope="col" className="text-[13px] px-6 pb-3 w-3/12">
                Task Name
              </th>
              {/* <th scope="col" className="text-[13px] px-6 pb-3 w-3/12">
                Transcript
              </th> */}
              <th
                scope="col"
                className="text-[13px] px-6 pb-3 w-6/12 text-center"
              >
                Status
              </th>
              <th
                scope="col"
                className="text-[13px] px-6 pb-3 w-6/12 text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTasks?.map((task, index) => (
              <tr key={index} className="border-t border-gray-700 h-[30px]">
                <th
                  scope="row"
                  className={`px-6 py-2 font-medium text-white whitespace-nowrap w-3/12`}
                >
                  {task?.id}
                </th>
                <td className="px-6 py-2 text-white w-3/12">
                  {task.task_name}
                </td>
                {/* <td className="px-6 py-2 text-white w-3/12">
                  <button
                    onClick={() => {
                      handleCallDetails(task?.call_tasks_id);
                      setIsModalOpen(true);
                    }}
                    className={`bg-green-500 text-white font-normal text-sm px-4 rounded-sm`}
                  >
                    Details
                  </button>
                </td> */}
                <td className="px-6 py-2 w-6/12 text-center">
                  <div
                    className={`${
                      task.task_status === "Completed"
                        ? "bg-green-light text-[#A9DFD8] border-[#A9DFD8]"
                        : "bg-pending-color text-[#FCB859] border-[#FCB859]"
                    } h-[22px] w-[77px] border-[0.5px] rounded-sm text-xs flex justify-center items-center mx-auto`}
                  >
                    {task.task_status}
                  </div>
                </td>
                <td
                  className="px-6 py-4 flex justify-center items-center gap-4 cursor-pointer hover:text-green-400"
                  onClick={() => {
                    handleTaskDetails(task);
                  }}
                >
                  <p className="border inline px-3 py-1 border-gray-500 hover:animate-pulse">
                    <FaEye />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-white py-4">
          No agent tasks performed.
        </div>
      )}
      {/* Hide pagination if no tasks are present */}
      {filteredTasks.length > 0 ? (
        <div>
          <div className="flex justify-end items-center text-sm text-gray-400 mt-2">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`px-2 py-1 rounded ${
                currentPage === 1
                  ? "text-white cursor-not-allowed"
                  : "text-white"
              }`}
            >
              <MdKeyboardArrowLeft size={16} />
            </button>
            {/* Count */}
            <div className="text-white text-xs opacity-85">{`${
              indexOfFirstTask + 1
            } - ${indexOfLastTask} of ${data.length}`}</div>
            {/* count end */}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`px-2 py-1 rounded ${
                currentPage === totalPages
                  ? "text-white cursor-not-allowed"
                  : "text-white"
              }`}
            >
              <MdKeyboardArrowRight size={16} />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <DetailModal
        data={selectedTask}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isLoading={isLoading}
      />
    </div>
  );
}
