import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getsingleTaskData, setSingleTaskData } from "../../redux";
import Layout from "../../pages/Layout";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { getAgentTaskByTaskId, updateAgentEscalation } from "../../api/agent";
import { toast } from "react-toastify";

export const SingleAgentDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const task = useSelector(getsingleTaskData);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [loading, setLoading] = useState(true); // ensure loading is true initially
  const [eTask, setETask] = useState(false);
  const toggleEscalationStatus = async (task) => {
    const newEscalationStatus =
      task.escalation_status === "Resolved" ? "Unresolved" : "Resolved";
    const updateData = {
      id: task.id,
      escalation_status: newEscalationStatus,
    };
    try {
      dispatch(
        setSingleTaskData({ ...task, escalation_status: newEscalationStatus })
      );

      // Call the API to update the escalation status
      const res = await updateAgentEscalation(updateData);
      if (res.status === 200) {
        dispatch(setSingleTaskData(res.data));
        toast.success("Escalation status updated successfully");
      } else {
        dispatch(setSingleTaskData(task));
        toast.error("Failed to update escalation status");
      }
    } catch (error) {
      console.error("Failed to update escalation status", error);
      dispatch(setSingleTaskData(task));
      toast.error("An error occurred while updating escalation status");
    }
  };

  useEffect(() => {
    getTaskDetails();
  }, [id]);
  const getTaskDetails = async () => {
    const taskDetails = await getAgentTaskByTaskId(id);
    dispatch(setSingleTaskData(taskDetails?.data));
    checkAuthorization(taskDetails?.data);
    setLoading(false);
  };

  const checkAuthorization = (taskDetails) => {
    const detail = localStorage.getItem("userDetails");
    const localId = JSON.parse(detail)?.client_id;
    if (taskDetails?.client_id === localId) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    getTaskDetails();
  }, [eTask]);

  const handleBack = () => {
    navigate("/");
  };

  return (
    <Layout showAgents={false}>
      <div className="flex w-full justify-start cursor-pointer pl-10 px-5">
        <button
          className="px-4 py-1 border-[1px] border-gray-500 mt-5"
          onClick={handleBack}
        >
          <FaArrowLeftLong className="w-7 h-7 cursor-pointer text-input-grey hover:text-gray-500 transition-all delay-100" />
        </button>
      </div>
      {loading ? (
        <div className="h-full flex justify-center items-center">
          <span className="loading loading-spinner text-info"></span>{" "}
        </div>
      ) : task && Object.keys(task)?.length > 0 ? (
        isAuthorized ? (
          <div className="p-4">
            <div className="px-6 flex flex-col gap-4">
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Task Name:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.task_name}
                </p>
              </div>
              {task?.agent_name && (
                <div className="w-full flex items-center gap-2 mb-2">
                  <p className="text-[18px] font-bold text-white w-[20%]">
                    Agent Name:
                  </p>
                  <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                    {task?.agent_name}
                  </p>
                </div>
              )}
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Customer Number:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.customer_phone_number}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Email:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.customer_email}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Task Completion Time (min:sec):
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.task_completed_time || task?.completed_time}{" "}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Task Performed At:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.task_performed_at}{" "}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Task Status:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.task_status}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Task Summary:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.task_summary}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Escalation:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.escalated ? "True" : "False"}
                </p>
              </div>
              {task?.escalation_reason && (
                <div className="w-full flex items-center gap-2 mb-2">
                  <p className="text-[18px] font-bold text-white w-[20%]">
                    Escalation Reason:
                  </p>
                  <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                    {task?.escalation_reason}
                  </p>
                </div>
              )}

              {/* Escalation Status Toggle */}
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Escalation Status:
                </p>
                <div
                  className="flex items-center gap-2"
                  onClick={() => toggleEscalationStatus(task)}
                >
                  <div className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      id="toggleSwitch"
                      checked={task.escalation_status === "Resolved"}
                      readOnly
                    />
                    <label
                      htmlFor="toggleSwitch"
                      className={`block w-24 h-8 rounded-full transition-colors ${
                        task.escalation_status === "Resolved"
                          ? "bg-green-500"
                          : "bg-gray-400"
                      }`}
                    >
                      <span
                        className={`absolute top-2 text-xs font-semibold transition-all ${
                          task.escalation_status === "Resolved"
                            ? "text-green-100"
                            : "text-gray-200"
                        }`}
                        style={{
                          left:
                            task.escalation_status === "Resolved"
                              ? ".5rem"
                              : "calc(100% - 4rem)",
                        }}
                      >
                        {task.escalation_status}
                      </span>
                    </label>
                    <div
                      className={`absolute top-0.5 w-7 h-7 bg-white border rounded-full shadow-md transform transition-transform ${
                        task.escalation_status === "Resolved"
                          ? "translate-x-16"
                          : "translate-x-0"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Order Tracking ID(s):
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.order_tracking_ids}
                </p>
              </div>
              <div className="w-full flex items-center gap-2 mb-2">
                <p className="text-[18px] font-bold text-white w-[20%]">
                  Call Transcript:
                </p>
                <p className="text-[16px] w-[80%] text-[#EEEEEE]">
                  {task?.call_transcript}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center">
            <p className="text-[78px] font-bold text-red-500 mb-2">
              Unauthorized
            </p>
          </div>
        )
      ) : (
        <div className="h-full flex justify-center items-center">
          <p className="text-[78px] font-bold text-green-500 mb-2">NOT FOUND</p>
        </div>
      )}
    </Layout>
  );
};
