import React, { useState } from "react";
import { getAgents, getNumber } from "../../redux";
import { useSelector } from "react-redux";
import AgentPhoneDetailsTable from "../table/AgentPhoneDetailsTable";

export const WorkflowDeploymentPage = () => {
  const data = ["Website", "Phone", "Mail"];
  const [selectedInfo, setSelectedInfo] = useState("");
  const agents = useSelector(getAgents);
  const userNumber = useSelector(getNumber);
  const hasDeployedPhone = agents.some(
    (agent) => agent["deployed_phone_number"] !== ""
  );

  const hasDeployedMail = agents.some(
    (agent) => agent["deployed_email"] !== null
  );

  const handleCardClick = (info) => {
    setSelectedInfo((prevInfo) => (prevInfo === info ? "" : info));
  };
  return (
    <div className="bg-[#101010] text-white min-h-screen relative p-8 space-y-4 customScroll">
      {/* <h2 className="font-bold">Enabled Deployment Configurations</h2>
      <RadioCard name={"Website"} type={"upload"} sub={"Website Link Here"} /> */}
      <h2 className="font-bold">Enabled Deployment Platforms</h2>
      <div className="w-full flex justify-start items-center flex-wrap gap-4">
        {data?.map((name, index) =>
          hasDeployedPhone && name === "Phone" ? (
            <div
              className="w-[8rem] px-4 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-agent-card rounded-md cursor-pointer"
              // onClick={() => {
              //   if (!hasDeployedPhone && name === "Phone") {
              //     setSelectedInfo(`Phone: ${"+92"}`);
              //   }
              // }}
              onClick={() => handleCardClick(`${userNumber}`)}
            >
              <p className="text-sm font-light">{"Phone"}</p>
            </div>
          ) : hasDeployedMail && name === "Mail" ? (
            <div
              className="w-[8rem] px-4 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-agent-card rounded-md"
              // onClick={() => {
              //   if (hasDeployedMail && name === "Mail") {
              //     setSelectedInfo(`Mail: ${"mail@gmail"}`);
              //   }
              // }}
              onClick={() => handleCardClick(`Mail: ${"mail@gmail"}`)}
            >
              <p className="text-sm font-light">{"Mail"}</p>
            </div>
          ) : (
            <></>
          )
        )}
        {/* Display the selected information */}
        {/* {selectedInfo && (
          <div className="absolute lg:top-[30%] lg:left-[3%] xl:top-[30%] xl:left-[3%] 2xl:top-[25%] 2xl:left-[3%] px-4 flex min-h-[7rem] model justify-center items-center min-w-[20rem] border border-dark-7 bg-agent-card rounded-md mt-4">
            <h2 className="font-bold w-full text-white text-start">
              Mobile Number
            </h2>
            <p className="text-sm font-light">{selectedInfo}</p>
          </div>
        )} */}
      </div>
      {selectedInfo && <AgentPhoneDetailsTable data={agents} />}
    </div>
  );
};
